<template>
  <div class="employee-actions">
    <span
      v-if="canEnrollEmployee(employee) && state.claims.a"
      v-b-tooltip.hover.noninteractive="employee?.instant_payment_method_status === 'AWAITING_INSTANT_PAYMENT_METHOD'"
      :title="
        employee?.instant_payment_method_status === 'AWAITING_INSTANT_PAYMENT_METHOD'
          ? 'Employee must link a debit card before they can be enrolled'
          : ''
      "
    >
      <b-button
        :to="{ name: 'employee-setup', params: { employeeId: employee.employee_id } }"
        variant="outline-primary"
        class="px-3"
        :disabled="employee?.instant_payment_method_status === 'AWAITING_INSTANT_PAYMENT_METHOD'"
      >
        <font-awesome-icon :icon="['fad', 'user-plus']" class="mr-2" />Enroll
      </b-button>
    </span>

    <b-button
      :to="{ name: 'employee-opt-in', params: { employeeId: employee.employee_id } }"
      variant="outline-primary"
      class="px-3"
      v-if="canOptInEmployee(employee)"
    >
      <font-awesome-icon :icon="['fad', 'user-plus']" class="mr-2" />Opt In
    </b-button>

    <b-button
      :to="{ name: 'employee-opt-out', params: { employeeId: employee.employee_id } }"
      variant="outline-primary"
      class="px-3"
      v-if="canOptOutEmployee(employee)"
    >
      <font-awesome-icon :icon="['fad', 'user-minus']" class="mr-2" />Opt Out
    </b-button>

    <b-button
      :to="{ name: 'employee-revert-banking', params: { employeeId: employee.employee_id } }"
      variant="outline-primary"
      class="px-3"
      v-if="canCompleteOptOutForEmployee(employee)"
    >
      <font-awesome-icon :icon="['fas', 'circle-check']" class="mr-2" />Complete Opt-Out
    </b-button>

    <b-button
      :to="{ name: 'employee-pause', params: { employeeId: employee.employee_id } }"
      variant="outline-primary"
      class="px-3"
      v-if="canPauseEmployee(employee)"
    >
      <font-awesome-icon :icon="['fas', 'pause']" class="mr-2" />Pause
    </b-button>

    <b-button
      :to="{ name: 'employee-unpause', params: { employeeId: employee.employee_id } }"
      variant="outline-primary"
      class="px-3"
      v-if="canUnpauseEmployee(employee)"
    >
      <font-awesome-icon :icon="['fad', 'play']" class="mr-2" />Unpause
    </b-button>

    <b-button
      :to="{ name: 'employee-invite', params: { employeeId: employee.employee_id } }"
      variant="outline-primary"
      class="px-3"
      v-if="!isEmployeeEnrolled(employee)"
    >
      <font-awesome-icon :icon="['fad', 'envelope']" class="mr-2" />Send Invite
    </b-button>

    <b-button
      :to="{ name: 'employee-reset-password', params: { employeeId: employee.employee_id } }"
      variant="outline-primary"
      class="px-3"
      v-if="canChangePasswordForEmployee(employee)"
    >
      <font-awesome-icon :icon="['fad', 'lock-open']" class="mr-2" />Reset Password
    </b-button>
  </div>
</template>

<script>
import State from '@/state/State';
import { employeeBadges } from '@/mixins/EmployeeBadges.js';
import {
  canCompleteOptOutForEmployee,
  canOptOutEmployee,
  canOptInEmployee,
  canPauseEmployee,
  canUnpauseEmployee,
  canChangePasswordForEmployee,
  canEnrollEmployee,
} from '@/utils/Employee';

export default {
  name: 'EmployeeActions',
  props: ['employee'],
  mixins: [employeeBadges],

  methods: {
    canEnrollEmployee,
    canCompleteOptOutForEmployee,
    canOptOutEmployee,
    canOptInEmployee,
    canPauseEmployee,
    canUnpauseEmployee,
    canChangePasswordForEmployee,
  },
  data() {
    return {
      state: State.state,
    };
  },
};
</script>

<style scoped lang="scss">
.employee-actions {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}
</style>
