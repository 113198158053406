import { convertBackendStatusToDisplayStatus, isDisabledOrOptedOutEmployee } from '@/utils/Employee';

export const employeeBadges = {
  methods: {
    badgeVariant(employee) {
      switch (this.convertStatus(employee)) {
        case 'NOT ENROLLED':
        case 'DISABLED':
        case 'LEAVER':
          return 'secondary';
        case 'OPTED OUT':
        case 'PAUSED':
        case 'PAUSE SCHEDULED':
          return 'secondary-light';
        case 'OPT OUT PENDING':
          return 'tertiary-light';
        case 'TRANSFERS DISABLED':
          return 'secondary-light';
        default:
          return 'primary-light';
      }
    },
    convertStatus(employee) {
      return convertBackendStatusToDisplayStatus(employee);
    },
    isEmployeeEnrolled(employee) {
      return employee.current_state === 'ACTIVE' || employee.current_state === 'ENROLLED';
    },
    isEmployeeDisabledOrOptedOut(employee) {
      // so that we can use the same source of truth for this function. this mixin should probably be deprecated
      return isDisabledOrOptedOutEmployee(employee);
    },
  },
};
