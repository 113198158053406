<template>
  <section>
    <page-sub-header title="App Logins" class="mt-4">
      <template #header>
        <div class="d-flex flex-row align-items-center justify-content-end">
          <app-input
            v-model="timeSeries"
            :options="TIME_SERIES_VALUES"
            type="radio"
            buttons
            button-variant="outline-primary"
            class="text-uppercase mb-0"
            size="sm"
          />
          <export-button
            v-if="isDataReady"
            :file-name="`app-logins-metrics-${timeSeries}`"
            :export-data="exportData"
            :export-file-rows="exportFileRows"
            :export-headers="exportHeaders"
            class="btn mt-0"
          />
        </div>
      </template>
    </page-sub-header>
    <sme-card v-if="loading">
      <app-loading />
    </sme-card>
    <template v-else>
      <sme-card v-if="timeGroupedUniqueUsersDataset.values.length" class="mb-4">
        <b-row class="align-items-center mb-4">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedUniqueUsersDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { ticks: { precision: 0 }, title: { text: timeGroupedUniqueUsersDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastGroupedSession"
              :previous-stat="prevGroupedSession"
              :formatter="toCompactNumber"
              description="App Logins"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ toCompactNumber(lastGroupedSession) }} of your enrolled employees logged in to
              the app.
              {{ sessionPrevDiffText }}
            </p>
          </b-col>
        </b-row>

        <b-row class="align-items-center">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedUniqueUsersPercentageDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { title: { text: timeGroupedUniqueUsersPercentageDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastGroupedSessionPercentage"
              :previous-stat="prevGroupedSessionPercentage"
              :formatter="toNumber"
              suffix="%"
              description="Login Percentage"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ lastGroupedSessionPercentage }}% of your enrolled employees with the app logged
              in.
              {{ sessionPercentagePrevDiffText }}
            </p>
          </b-col>
        </b-row>
      </sme-card>

      <b-row class="mb-4">
        <b-col lg="4" class="mb-4 mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="mean(averageVisitsPerUserDataset.values) || 0"
              :formatter="Math.round"
              :description="`Average app visits per user per ${timeSeriesUnit}`"
              :class="[averageVisitsPerUserDataset.values.length && 'mb-3']"
              background-color="core-5-lighten-90"
              color="default"
              small
            />
            <line-chart
              v-if="averageVisitsPerUserDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="averageVisitsPerUserDataset"
              :chart-options="CHART_PRESET_OPTIONS.MINIMAL"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
        <b-col lg="4" class="mb-4 mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="mean(averageTimePerUserDataset.values) || 0"
              :formatter="Math.round"
              :description="`Average minutes on app per user per ${timeSeriesUnit}`"
              :class="[averageTimePerUserDataset.values.length && 'mb-3']"
              background-color="core-4-lighten-90"
              color="default"
              small
            />
            <line-chart
              v-if="averageTimePerUserDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="averageTimePerUserDataset"
              :chart-options="CHART_PRESET_OPTIONS.MINIMAL"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
        <b-col lg="4" class="mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="uniqueDaysDataset.calculated.mean || 0"
              :formatter="Math.round"
              :description="`Average days per ${timeSeriesUnit} on app`"
              :class="[uniqueDaysDataset.values.length && 'mb-3']"
              background-color="core-3-lighten-80"
              color="default"
              small
            />
            <line-chart
              v-if="uniqueDaysDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="uniqueDaysDataset"
              :chart-options="CHART_PRESET_OPTIONS.MINIMAL"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
      </b-row>
    </template>
  </section>
</template>

<script setup>
import mean from 'lodash/mean';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { PaletteColors } from '@/Theme';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppStat from '@/components/AppStat.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import LineChart, { CHART_PRESET_OPTIONS } from '@/components/charts/LineChart.vue';
import useTimeGroupDataset from '@/composables/useTimeGroupDataset';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import { TIME_SERIES_VALUES, getInitialAspectRatio } from '@/utils/chart';
import { toCompactNumber, toNumber } from '@/utils/common';
import ExportButton from '@/components/ExportButton.vue';

const { divisionId } = useDivisionSelection();

const loading = ref(true);
const timeDataset = ref(null);
const uniqueUsersDataset = ref(null);
const uniqueUsersPercentageDataset = ref(null);
const averageVisitsPerUserDataset = ref(null);
const averageTimePerUserDataset = ref(null);
const uniqueDaysDataset = ref(null);

const { timeGroupDataset, timeSeries, timeSeriesUnit, timeGroupTitle, datasetToTimeGroupedDataset, getPrevDiffText } =
  useTimeGroupDataset(timeDataset);

const timeGroupedUniqueUsersDataset = computed(() => datasetToTimeGroupedDataset(uniqueUsersDataset));
const timeGroupedUniqueUsersPercentageDataset = computed(() =>
  datasetToTimeGroupedDataset(uniqueUsersPercentageDataset),
);
const lastGroupedSession = computed(() => timeGroupedUniqueUsersDataset.value?.calculated.last);
const prevGroupedSession = computed(() => timeGroupedUniqueUsersDataset.value?.calculated.prev);
const lastGroupedSessionPercentage = computed(() => timeGroupedUniqueUsersPercentageDataset.value?.calculated.last);
const prevGroupedSessionPercentage = computed(() => timeGroupedUniqueUsersPercentageDataset.value?.calculated.prev);
const sessionPrevDiffText = computed(() => getPrevDiffText(timeGroupedUniqueUsersDataset));
const sessionPercentagePrevDiffText = computed(() =>
  getPrevDiffText(timeGroupedUniqueUsersPercentageDataset, value => `${Number(toNumber(value))}%`),
);

watch([divisionId, timeSeries], () => getAppLoginsMetrics());

const getAppLoginsMetrics = async () => {
  loading.value = true;

  const { results } = await ApiClient.getAppLoginsMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const uniqueUsers = results.find(result => result.id === 'unique_users_in_app');
    const enrolled = results.find(result => result.id === 'enrolled');
    const sessions = results.find(result => result.id === 'sessions');
    const totalTime = results.find(result => result.id === 'total_time_on_app_seconds');
    const uniqueDays = results.find(result => result.id === 'unique_days_in_app');

    timeDataset.value = time;
    uniqueUsersDataset.value = {
      ...uniqueUsers,
      name: 'Number of logins',
    };
    uniqueUsersPercentageDataset.value = {
      name: 'Percentage (%)',
      values: enrolled.values.map((value, index) =>
        value ? Number(toNumber((uniqueUsers.values[index] / value) * 100)) : 0,
      ),
    };
    averageVisitsPerUserDataset.value = {
      name: 'Visits per user',
      values: uniqueUsers.values.map((value, index) => (value ? sessions.values[index] / value : 0)),
      lineColor: PaletteColors['core-5'],
    };
    averageTimePerUserDataset.value = {
      name: 'Minutes on app',
      values: uniqueUsers.values.map((value, index) => (value ? totalTime.values[index] / 60 / value : 0)),
      lineColor: PaletteColors['core-4'],
    };
    uniqueDaysDataset.value = {
      ...uniqueDays,
      name: 'Unique days on app',
      lineColor: PaletteColors['core-3'],
    };
  }

  loading.value = false;
};

onBeforeMount(() => getAppLoginsMetrics());

const isDataReady = computed(() => {
  return (
    timeGroupDataset.value?.values &&
    uniqueUsersDataset.value?.values &&
    uniqueUsersPercentageDataset.value?.values &&
    averageVisitsPerUserDataset.value?.values &&
    averageTimePerUserDataset.value?.values &&
    uniqueDaysDataset.value?.values &&
    !loading.value
  );
});

const exportData = computed(() => {
  if (!isDataReady.value) {
    return [];
  }

  return timeGroupDataset.value.values.map((time, index) => ({
    time,
    logins: uniqueUsersDataset.value.values[index],
    percentage: uniqueUsersPercentageDataset.value.values[index],
    averageVisits: averageVisitsPerUserDataset.value.values[index],
    averageMinutes: averageTimePerUserDataset.value.values[index],
    uniqueDays: uniqueDaysDataset.value.values[index],
  }));
});

const exportHeaders = computed(() => {
  if (!isDataReady.value) {
    return [];
  }

  return [
    'Date',
    uniqueUsersDataset.value.name,
    uniqueUsersPercentageDataset.value.name,
    'Average Visits per User',
    'Average Minutes per User',
    'Average Days per User',
  ];
});

const exportFileRows = item => [
  item.time,
  item.logins,
  item.percentage,
  Number(item.averageVisits.toFixed(2)),
  Number(item.averageMinutes.toFixed(2)),
  Number(item.uniqueDays.toFixed(2)),
];
</script>
