<template>
  <section>
    <page-sub-header :title="bankingInfo.save.label" class="mt-4">
      <template #header>
        <div class="d-flex flex-row align-items-center justify-content-end">
          <app-input
            v-model="timeSeries"
            :options="TIME_SERIES_VALUES"
            type="radio"
            buttons
            button-variant="outline-primary"
            class="text-uppercase mb-0"
            size="sm"
          />
          <export-button
            v-if="isDataReady"
            :file-name="`build-metrics-${timeSeries}`"
            :export-data="exportData"
            :export-file-rows="exportFileRows"
            :export-headers="exportHeaders"
            class="btn mt-0"
          />
        </div>
      </template>
    </page-sub-header>
    <sme-card v-if="loading">
      <app-loading />
    </sme-card>
    <template v-else>
      <sme-card v-if="timeGroupedPotsOpenedDataset.values.length" class="mb-4">
        <b-row class="align-items-center mb-4">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedPotsOpenedDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { ticks: { precision: 0 }, title: { text: timeGroupedPotsOpenedDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastGroupedPotsOpened"
              :previous-stat="prevGroupedPotsOpened"
              :formatter="toCompactNumber"
              description="Builders"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ toCompactNumber(lastGroupedPotsOpened) }} of your employees had a
              {{ bankingInfo.save.potLabel }}.
              {{ potsOpenedPrevDiffText }}
            </p>
          </b-col>
        </b-row>

        <b-row class="align-items-center">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedPotsOpenedPercentageDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { title: { text: timeGroupedPotsOpenedPercentageDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastGroupedPotsOpenedPercentage"
              :previous-stat="prevGroupedPotsOpenedPercentage"
              :formatter="toNumber"
              suffix="%"
              description="Builders Percentage"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ lastGroupedPotsOpenedPercentage }}% of your enrolled employees had a build pot.
              {{ potsOpenedPercentagePrevDiffText }}
            </p>
          </b-col>
        </b-row>
      </sme-card>

      <b-row>
        <b-col cols="6" :lg="hasPrizeMetrics ? 3 : 6" class="mb-4 mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="totalIngoingsDataset.calculated.mean || 0"
              :formatter="value => toCompactNumber(Math.round(value))"
              :prefix="currencySymbolFromCode(State.state.company.default_currency)"
              :description="`Average ${timeSeriesUnit}ly deposits total`"
              :class="[totalIngoingsDataset.values.length && 'mb-3']"
              background-color="core-5-lighten-90"
              color="default"
              small
            />
            <line-chart
              v-if="totalIngoingsDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="totalIngoingsDataset"
              :chart-options="merge({}, CHART_PRESET_OPTIONS.CURRENCY, CHART_PRESET_OPTIONS.MINIMAL)"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
        <b-col cols="6" :lg="hasPrizeMetrics ? 3 : 6" class="mb-4 mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="averageEOMDataset.calculated.mean || 0"
              :formatter="value => toCompactNumber(Math.round(value))"
              :prefix="currencySymbolFromCode(State.state.company.default_currency)"
              description="Average end of month balance"
              :class="[averageEOMDataset.values.length && 'mb-3']"
              background-color="core-4-lighten-90"
              color="default"
              small
            />
            <line-chart
              v-if="averageEOMDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="averageEOMDataset"
              :chart-options="merge({}, CHART_PRESET_OPTIONS.CURRENCY, CHART_PRESET_OPTIONS.MINIMAL)"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
        <template v-if="hasPrizeMetrics">
          <b-col cols="6" lg="3" class="mb-4 mb-lg-0">
            <sme-card class="h-100 overflow-hidden">
              <app-stat
                :stat="prizeWinnersDataset.calculated.mean || 0"
                :formatter="Math.round"
                :description="`Average ${timeSeriesUnit}ly prize winners`"
                :class="[prizeWinnersDataset.values.length && 'mb-3']"
                background-color="core-3-lighten-80"
                color="default"
                small
              />
              <line-chart
                v-if="prizeWinnersDataset.values.length"
                :labels="timeGroupDataset"
                :datasets="prizeWinnersDataset"
                :chart-options="CHART_PRESET_OPTIONS.MINIMAL"
                :display-points="false"
                class="mx-n3 mt-auto mb-n3"
                fill-gradient
              />
            </sme-card>
          </b-col>
          <b-col cols="6" lg="3" class="mb-4 mb-lg-0">
            <sme-card class="h-100 overflow-hidden">
              <app-stat
                :stat="(totalPrizeWinningsDataset.calculated.mean || 0) / 100"
                :formatter="value => toCompactNumber(Math.round(value))"
                :prefix="currencySymbolFromCode(State.state.company.default_currency)"
                :description="`Average ${timeSeriesUnit}ly prize amount won`"
                :class="[totalPrizeWinningsDataset.values.length && 'mb-3']"
                background-color="core-1-lighten-80"
                color="default"
                small
              />
              <line-chart
                v-if="totalPrizeWinningsDataset.values.length"
                :labels="timeGroupDataset"
                :datasets="totalPrizeWinningsDataset"
                :chart-options="merge({}, CHART_PRESET_OPTIONS.CURRENCY, CHART_PRESET_OPTIONS.MINIMAL)"
                :display-points="false"
                class="mx-n3 mt-auto mb-n3"
                fill-gradient
              />
            </sme-card>
          </b-col>
        </template>
      </b-row>
    </template>
  </section>
</template>

<script setup>
import merge from 'lodash/merge';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import { PaletteColors } from '@/Theme';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppStat from '@/components/AppStat.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import LineChart, { CHART_PRESET_OPTIONS } from '@/components/charts/LineChart.vue';
import useTimeGroupDataset from '@/composables/useTimeGroupDataset';
import State from '@/state/State';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import { getInitialAspectRatio, TIME_SERIES_VALUES } from '@/utils/chart';
import { toCompactNumber, toNumber, currencySymbolFromCode } from '@/utils/common';
import ExportButton from '@/components/ExportButton.vue';

const { divisionId } = useDivisionSelection();

const loading = ref(true);
const timeDataset = ref(null);
const potsOpenedDataset = ref(null);
const potsOpenedPercentageDataset = ref(null);
const averageEOMDataset = ref(null);
const totalIngoingsDataset = ref(null);
const prizeWinnersDataset = ref(null);
const totalPrizeWinningsDataset = ref(null);

const { timeGroupDataset, timeGroupTitle, timeSeries, timeSeriesUnit, datasetToTimeGroupedDataset, getPrevDiffText } =
  useTimeGroupDataset(timeDataset);

const timeGroupedPotsOpenedDataset = computed(() => datasetToTimeGroupedDataset(potsOpenedDataset));
const timeGroupedPotsOpenedPercentageDataset = computed(() => datasetToTimeGroupedDataset(potsOpenedPercentageDataset));
const lastGroupedPotsOpened = computed(() => timeGroupedPotsOpenedDataset.value?.calculated.last);
const prevGroupedPotsOpened = computed(() => timeGroupedPotsOpenedDataset.value?.calculated.prev);
const lastGroupedPotsOpenedPercentage = computed(() => timeGroupedPotsOpenedPercentageDataset.value?.calculated.last);
const prevGroupedPotsOpenedPercentage = computed(() => timeGroupedPotsOpenedPercentageDataset.value?.calculated.prev);
const potsOpenedPrevDiffText = computed(() => getPrevDiffText(timeGroupedPotsOpenedDataset));
const potsOpenedPercentagePrevDiffText = computed(() =>
  getPrevDiffText(timeGroupedPotsOpenedPercentageDataset, value => `${Number(toNumber(value))}%`),
);
const hasPrizeMetrics = computed(() => prizeWinnersDataset.value.values.some(value => value));
const bankingInfo = computed(() => getBankingInfo());

const isDataReady = computed(() => {
  return (
    timeGroupDataset.value?.values &&
    potsOpenedDataset.value?.values &&
    potsOpenedPercentageDataset.value?.values &&
    averageEOMDataset.value?.values &&
    totalIngoingsDataset.value?.values &&
    prizeWinnersDataset.value?.values &&
    totalPrizeWinningsDataset.value?.values &&
    !loading.value
  );
});

const exportData = computed(() => {
  if (!isDataReady.value) {
    return [];
  }

  return timeGroupDataset.value.values.map((time, index) => ({
    time,
    builders: potsOpenedDataset.value.values[index],
    percentage: potsOpenedPercentageDataset.value.values[index],
    averageBalance: averageEOMDataset.value.values[index],
    totalDeposits: totalIngoingsDataset.value.values[index],
    prizeWinners: prizeWinnersDataset.value.values[index],
    prizeAmount: totalPrizeWinningsDataset.value.values[index],
  }));
});

const exportHeaders = computed(() => {
  if (!isDataReady.value) {
    return [];
  }
  return [
    'Date',
    potsOpenedDataset.value.name,
    potsOpenedPercentageDataset.value.name,
    'Average Balance',
    totalIngoingsDataset.value.name,
    prizeWinnersDataset.value.name,
    totalPrizeWinningsDataset.value.name,
  ];
});

const exportFileRows = item => [
  item.time,
  item.builders,
  item.percentage,
  Math.round(item.averageBalance),
  Math.round(item.totalDeposits),
  item.prizeWinners,
  Math.round(item.prizeAmount),
];

watch([divisionId, timeSeries], () => getBuildMetrics());

const getBuildMetrics = async () => {
  loading.value = true;

  const { results } = await ApiClient.getBuildMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const potsOpened = results.find(result => result.id === 'pots_opened');
    const enrolled = results.find(result => result.id === 'enrolled');
    const averageEOMBalance = results.find(result => result.id === 'avg_eom_pot_balance');
    const totalIngoings = results.find(result => result.id === 'total_ingoings');
    const prizeWinners = results.find(result => result.id === 'number_of_prize_winners');
    const totalPrizeWinnings = results.find(result => result.id === 'total_prize_amount_won_pence');

    timeDataset.value = time;
    potsOpenedDataset.value = {
      ...potsOpened,
      name: 'Number of builders',
    };
    potsOpenedPercentageDataset.value = {
      name: 'Percentage (%)',
      values: enrolled.values.map((value, index) =>
        value ? Number(toNumber((potsOpened.values[index] / value) * 100)) : 0,
      ),
    };
    averageEOMDataset.value = {
      ...averageEOMBalance,
      name: 'Balance',
      lineColor: PaletteColors['core-4'],
    };
    totalIngoingsDataset.value = {
      ...totalIngoings,
      name: 'Deposits total',
      lineColor: PaletteColors['core-5'],
    };
    prizeWinnersDataset.value = {
      ...prizeWinners,
      name: 'Prize winners',
      lineColor: PaletteColors['core-3'],
    };
    totalPrizeWinningsDataset.value = {
      ...totalPrizeWinnings,
      name: 'Prize amount won',
      values: totalPrizeWinnings.values.map(value => toNumber(value / 100)),
      lineColor: PaletteColors['core-2-lighten-20'],
    };
  }

  loading.value = false;
};

onBeforeMount(() => getBuildMetrics());
</script>
