import { dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import * as Sentry from '@sentry/vue';
import BootstrapVue from 'bootstrap-vue';
import * as moment from 'moment/moment';
import { Userpilot } from 'userpilot';
import { extend, configure } from 'vee-validate';
import {
  required,
  regex,
  length,
  min,
  max,
  confirmed,
  min_value,
  max_value,
  numeric,
  integer,
  oneOf,
} from 'vee-validate/dist/rules.js';
import Vue from 'vue';
import VueIntercom from 'vue-intercom';
import { isLoggedIn, handleLogin, getToken, getRefreshToken, logout } from '@/ApiClient.js';
import App from '@/App.vue';
import router from '@/Router';
import { initTheme } from '@/Theme';
import AppToasts from '@/plugins/AppToasts';
import '@/icons';
import '@/styles/root.scss';
import store from '@/state';
import { getPartnershipData } from '@/utils/partnership';
import { setVm } from '@/vm';
import { VueQueryPlugin } from '@tanstack/vue-query';
import State from '@/state/State';

const ignorableErrors = [
  /Redirected when going from/,
  /Not enough storage is available to complete this operation/,
  /timeout of 0ms exceeded/,
  /NavigationDuplicated/,
  /Error: Network Error/, // sent by axios, we should catch these ourselves
  /Network Error/, // sent by axios, we should catch these ourselves
  /Request failed with status code 401/, // sent by axios, we should catch these ourselves
  /Object captured as promise rejection with keys: message/, // propagated errors after an api call failure
  /\.staging\./,
  /localhost:8001/,
  /jQuery is not defined/, // TODO this needs fixing, think google tag manager is executing code which needs jquery
  /Can't find variable: jQuery/, // TODO this needs fixing, think google tag manager is executing code which needs jquery
  /Error response from ApiClient: API didn't respond/,
];

let lastEventTime = null;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://b7f934cfb5a84a7d967c2f8d5f2c7280@o315138.ingest.sentry.io/4504487457652736',
    integrations: [Sentry.browserTracingIntegration({ router })],
    environment: process.env.NODE_ENV,
    ignoreErrors: ignorableErrors,
    release: `sme-portal@${process.env.VUE_APP_GIT_HASH}`,
    beforeSend(event, hint) {
      const eventTime = new Date();
      const eventMessage = hint?.originalException?.toString();

      // skip if it is an impersonated user as can cause issues with claims
      if (State?.state?.claims?.imp) {
        return null;
      }

      // double check error content for ignored errors
      if (eventMessage && ignorableErrors.some(regex => regex.test(eventMessage))) {
        return null;
      }

      // debounce errors. better not to send multiple errors in a short time to keep the alerts channel usable
      // as long as we fix the errors in the channel, we should see the ignored errors in time
      if (lastEventTime && eventTime - lastEventTime < 1000) {
        return null;
      }

      lastEventTime = eventTime;
      return event;
    },
  });

  Sentry.setContext('environment', {
    hostname: document.location.hostname,
  });
}

if (process.env.VUE_APP_USERPILOT_TOKEN) {
  Userpilot.initialize(process.env.VUE_APP_USERPILOT_TOKEN);
}

// eslint-disable-next-line
Vue.component('font-awesome-icon', FontAwesomeIcon);
// eslint-disable-next-line
Vue.component('font-awesome-layers', FontAwesomeLayers);
dom.watch();

Vue.use(BootstrapVue);

extend('email', value => {
  return value.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
  );
});
extend('required', required);
extend('regex', regex);
extend('length', length);
extend('min', min);
extend('max', max);
extend('confirmed', confirmed);
extend('min_value', min_value);
extend('max_value', max_value);
extend('numeric', numeric);
extend('integer', integer);
extend('oneOf', oneOf);
extend('date_format', {
  validate(value, args) {
    return moment(value, args.format, true).isValid();
  },
  params: ['format'],
  message: 'Incorrect date format for {_field_}',
});
extend('after', {
  validate(value, args) {
    if (!args.date) {
      return true;
    }

    return moment(value, args.format, true).isSameOrAfter(moment(args.date, args.format, true));
  },
  params: ['date', 'format'],
  message: '{_field_} must be after {date}',
});
extend('password', {
  validate(value) {
    return (
      value?.length >= 12 && value.match(/[a-z]/) && value.match(/[A-Z]/) && value.match(/\d/) && value.match(/\W/)
    );
  },
  message: 'Password is not valid',
});
extend('confirmed', {
  validate(value, { target }) {
    return value === target;
  },
  message: 'Passwords do not match',
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
});
extend('name', value => {
  return value.match(/^[0-9'A-Za-z\- ]+$/g);
});
extend('phone', value => {
  return value.match(/^\+?\d+$/g);
});
extend('postcode', value => {
  return value.match(/^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/g);
});

Vue.use(AppToasts);
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID, partner_portal: true, hide_default_launcher: true });
// Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.use(VueQueryPlugin);

const getVueApp = async () => {
  const { themeId } = getPartnershipData();

  await initTheme(themeId);

  if (isLoggedIn()) {
    try {
      await handleLogin(getToken(), getRefreshToken());
    } catch (e) {
      logout();
    }
  }

  return new Vue({
    router,
    render: h => h(App),
    store,

    mounted() {
      this._keyListener = function (e) {
        if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          const accessToken = window.localStorage.getItem('accessToken');
          const refreshToken = window.localStorage.getItem('refreshToken');
          navigator.clipboard.writeText(`${accessToken}::::${refreshToken}`);
        } else if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          navigator.clipboard.readText().then(text => {
            if (text.indexOf('::::') != -1) {
              const parts = text.split('::::');
              window.localStorage.setItem('accessToken', parts[0]);
              window.localStorage.setItem('refreshToken', parts[1]);
              window.location.reload();
            }
          });
        }
      };
      document.addEventListener('keydown', this._keyListener.bind(this));
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this._keyListener);
    },
  });
};

const vm = getVueApp();

vm.then(v => {
  v.$mount('#app');
});

setVm(vm);
