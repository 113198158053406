import * as moment from 'moment/moment';
import { SALARY_TYPE } from '@/utils/Payschedule';

const PORTAL_PAYSCHEDULE_FORMAT = {
  accrual_type: undefined,
  name: undefined,
  next_payday: undefined,
  num_employees: undefined,
  pay_period_start: undefined,
  blackout_period_start: undefined,
  pay_schedule_code: undefined,
  pay_schedule_id: undefined,
  periodicity: undefined,
  dates: [],
};

export const convertPayschedules = payschedules => {
  const outputPayschedules = [];
  payschedules.forEach(inputPayschedule => {
    const outputPayschedule = { ...PORTAL_PAYSCHEDULE_FORMAT };
    outputPayschedule.accrual_type = inputPayschedule.accrual_type;
    outputPayschedule.name = inputPayschedule.name;

    const currentPeriod = getCurrentPeriod(inputPayschedule);
    outputPayschedule.next_payday = currentPeriod?.paid_on;
    outputPayschedule.pay_period_start = currentPeriod?.pay_period_start;
    outputPayschedule.blackout_period_start = currentPeriod?.blackout_period_start;

    outputPayschedule.pay_schedule_code = inputPayschedule?.properties?.pay_schedule_code || SALARY_TYPE.MIXED;
    outputPayschedule.pay_schedule_id = inputPayschedule.pay_schedule_id;
    outputPayschedule.periodicity = inputPayschedule.periodicity;
    outputPayschedule.dates = inputPayschedule.dates;
    outputPayschedule.employee_count = inputPayschedule.employee_count;
    outputPayschedules.push(outputPayschedule);
  });
  return outputPayschedules;
};

const getCurrentPeriod = payschedule => {
  if (payschedule.dates.length) {
    return payschedule.dates.find(d => moment(d.paid_on) > moment()) || undefined;
  } else {
    return undefined;
  }
};
