<template>
  <app-page>
    <template v-slot:custom-content>
      <dashboard-terms-modal />
      <terms-amendment-modal
        v-if="shouldAcceptSmeTermsOfBusinessAmendment"
        :termsAmendment="SME_TERMS_OF_BUSINESS_AMENDMENT"
      />
      <terms-amendment-modal
        v-if="shouldAcceptSalaryServiceAddendumDb"
        :termsAmendment="DB_SALARY_SERVICE_ADDENDUM"
        :canDismiss="false"
      />
      <terms-amendment-modal
        v-if="shouldAcceptMhrTermsOfBusinessAmendment"
        :termsAmendment="MHR_TERMS_OF_BUSINESS_AMENDMENT"
      />
      <terms-amendment-modal
        v-if="shouldAcceptHealthcareTermsOfBusinessAmendment"
        :termsAmendment="HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT"
      />
      <b-container v-if="isEnableSmeLaunchV2">
        <SmeCard>
          <b-row>
            <b-col>
              <b-card-text>
                <h1 class="h3">Welcome to Wagestream! 🚀</h1>
                <p>
                  Congratulations! You're one step closer to giving your employees access to fair financial services.
                </p>
                <p>
                  We've sent an email to <a href="">{{ State?.state?.user?.email }}</a> with instructions on how to get
                  started.
                </p>
              </b-card-text>
            </b-col>
          </b-row>
        </SmeCard>
      </b-container>
      <b-container class="pb-4" v-else>
        <sme-tour v-if="isTourApplicable" :tour="dashboardTour" class="mt-n4 mb-3" id="dashboard-tour" auto-start />
        <DashboardRaisePromo v-if="isEnableTalentPool" />
        <dashboard-header-stats class="mb-2" />
        <b-row>
          <b-col lg="6" class="mb-4 mb-lg-0">
            <dashboard-todos v-if="showDashboardTodos" data-id="dashboard.todos" />
            <dashboard-employees data-id="dashboard.employees" />
          </b-col>
          <b-col lg="6">
            <dashboard-payschedules v-if="State.state.claims.a" data-id="dashboard.payschedules" />
            <dashboard-shifts data-id="dashboard.shifts" />
          </b-col>
        </b-row>
        <dashboard-adoption v-if="isActivating && !isSelfFunded && isEnableAdoptionGamification" class="mt-4 p-0" />
      </b-container>
    </template>
  </app-page>
</template>

<script setup>
import { computed } from 'vue';
import AppPage from '@/components/AppPage.vue';
import SmeTour from '@/components/Tour.vue';
import useFeatureFlages from '@/composables/useFeatureFlags';
import DashboardAdoption from '@/pages/dashboard/components/DashboardAdoption.vue';
import DashboardHeaderStats from '@/pages/dashboard/components/DashboardHeaderStats.vue';
import TermsAmendmentModal from '@/pages/dashboard/components/TermsAmendmentModal.vue';
import DashboardTermsModal from '@/pages/dashboard/components/DashboardTermsModal.vue';
import DashboardEmployees from '@/pages/dashboard/components/employees/DashboardEmployees.vue';
import DashboardPayschedules from '@/pages/dashboard/components/payschedules/DashboardPayschedules.vue';
import DashboardShifts from '@/pages/dashboard/components/shifts/DashboardShifts.vue';
import DashboardTodos from '@/pages/dashboard/components/todos/DashboardTodos.vue';
import dashboardTour from '@/pages/dashboard/dashboardTour';
import DashboardRaisePromo from '@/pages/dashboard/components/raisePromo/DashboardRaisePromo.vue';
import State from '@/state/State';
import useEmployees from '@/state/composables/useEmployees';
import useLaunchState from '@/state/composables/useLaunchState';
import { TODO_ACTIONS } from '@/utils/Todo';
import {
  DB_SALARY_SERVICE_ADDENDUM,
  SME_TERMS_OF_BUSINESS_AMENDMENT,
  MHR_TERMS_OF_BUSINESS_AMENDMENT,
  HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT,
} from '@/utils/agreements';
import SmeCard from '@/components/atoms/SmeCard.vue';

const {
  isSelfFunded,
  isEnableAdoptionGamification,
  isEnableTalentPool,
  isEnableSmeLaunchV2,
  shouldAcceptSmeTermsOfBusinessAmendment,
  shouldAcceptSalaryServiceAddendumDb,
  shouldAcceptMhrTermsOfBusinessAmendment,
  shouldAcceptHealthcareTermsOfBusinessAmendment,
} = useFeatureFlages();

const TOUR_TODO_ACTIONS = [
  TODO_ACTIONS.CONFIGURE_PAYSCHEDULE,
  TODO_ACTIONS.ADD_EMPLOYEES,
  TODO_ACTIONS.KYC_PENDING,
  TODO_ACTIONS.FUND_PENDING,
];

const { getEnrolmentSnapshot } = useEmployees();
const { getLaunchState, hasTodoAction, isActivating, outstandingTodos } = useLaunchState();

const isTourApplicable = computed(
  () =>
    TOUR_TODO_ACTIONS.some(hasTodoAction) &&
    // ensure that tour does not display over terms modal
    !(outstandingTodos.value ? outstandingTodos?.value.find(t => t.action === 'accept.terms') !== undefined : true),
);

Promise.all([getEnrolmentSnapshot(), getLaunchState()]);

const showDashboardTodos =
  State.state.claims.a ||
  (State.state.claims.m && State.state.company?.properties?.portal?.enable_portal_tasks_for_managers);
</script>
