<template>
  <ValidationObserver v-slot="v">
    <b-form @submit.prevent="v.handleSubmit(onSave)">
      <app-input
        v-model="userState.username"
        v-if="!hideUsername"
        :rules="{ required: true, regex: /[a-zA-Z][a-zA-Z0-9_]*$/ }"
        :extraState="!duplicates.username"
        type="text"
        name="username"
        label="Username"
        @input="onUsernameInput"
      >
        <b-form-invalid-feedback v-if="duplicates.username"
          >Username is taken, please choose another one</b-form-invalid-feedback
        >
      </app-input>

      <app-input
        v-model="userState.email"
        rules="required|email"
        :extraState="!duplicates.email"
        :validate-immediate="false"
        type="text"
        name="email"
        label="Email"
        @input="onEmailInput"
      >
        <b-form-invalid-feedback v-if="duplicates.email"
          >The email is already in user by another user, please choose another one</b-form-invalid-feedback
        >
      </app-input>

      <app-input
        v-model="userState.phone"
        rules="required"
        :extraState="!duplicates.phone"
        :validate-immediate="false"
        type="text"
        name="phone"
        label="Phone"
        @input="onPhoneInput"
      >
        <b-form-invalid-feedback v-if="duplicates.phone"
          >The phone number is already in use by another user, please choose another one</b-form-invalid-feedback
        >
      </app-input>

      <app-input
        vid="password"
        v-model="userState.password"
        :validate-immediate="false"
        rules="required|password"
        type="password"
        name="password"
        label="Password"
        description="Password must be at least 12 characters long and include 1 uppercase, 1 lowercase, 1 number, and 1 special character."
        autocomplete="new-password"
      ></app-input>

      <app-input
        v-model="userState.confirm"
        :validate-immediate="false"
        rules="required|password|confirmed:password"
        data-vv-as="password"
        type="password"
        name="confirm"
        label="Confirm Password"
        autocomplete="new-password"
      ></app-input>

      <page-sub-footer>
        <template #before>
          <sme-alert v-if="error" level="danger">{{ error }}</sme-alert>
        </template>
        <b-button data-testid="userform-add" type="submit" variant="primary" :disabled="v.invalid || saving">
          <b-spinner v-if="saving" class="mr-2" small />
          {{ saving ? 'Adding...' : 'Add' }}
        </b-button>
        <b-button v-if="cancelUrl" :to="cancelUrl" variant="outline-primary">
          <template v-if="cancelUrl.name.indexOf('sme-register') >= 0"> Skip for now </template>
          <template v-else>Cancel</template>
        </b-button>
      </page-sub-footer>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import AppInput from '@/components/AppInput.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';

export default {
  name: 'UserForm',
  components: { ValidationObserver, AppInput, PageSubFooter, SmeAlert },
  model: {
    prop: 'user',
    event: 'input',
  },
  props: {
    user: Object,
    error: String,
    saving: Boolean,
    duplicates: Object,
    cancelUrl: Object,
    hideUsername: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userState: this.user,
    };
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.$emit('input', this.userState);
      },
    },
  },
  methods: {
    onSave() {
      this.$emit('save');
    },
    onUsernameInput() {
      this.$emit('duplicate-input', 'username');
    },
    onPhoneInput() {
      this.$emit('duplicate-input', 'phone');
    },
    onEmailInput() {
      this.$emit('duplicate-input', 'email');
    },
  },
};
</script>
