<template>
  <sme-card title="Employees" :to="{ name: 'employees' }">
    <p>
      You should add all your employees to the portal. Once they have been invited, they can opt in to start using the
      app.
    </p>
    <app-loading :loading="loading" />
    <template v-if="!loading">
      <sme-alert v-if="isAddEmployeesRequired" level="danger">
        You must upload employees before they can start using the app.
      </sme-alert>
      <div v-else>
        <sme-info-box id="employee-statuses" title="What do my employees' statuses mean?">
          <h4>Not Enrolled</h4>
          <p>
            If an employee is <strong>not enrolled</strong>, it means that you have invited the employee, but they have
            not yet downloaded the app.
          </p>
          <h4>Enrolling</h4>
          <p>
            If an employee is <strong>enrolling</strong>, it means they have requested to use the app and are waiting
            for you to enroll them.
          </p>
          <h4>Enrolled</h4>
          <p>
            If an employee is <strong>enrolled</strong>, it means they are set up and have full access to all the
            benefits of the app.
          </p>
        </sme-info-box>
        <dashboard-employees-chart :series="series" :total-employees="employeeStateCounts.ELIGIBLE" />
        <template v-if="isPayscheduleBlackout">
          <sme-alert level="warning" class="mb-3"> Enrollment blackout is in place </sme-alert>
          <p class="mb-0">
            This enrollment blackout prevents employees from being enrolled on/ near your pay day. This blackout will be
            lifted on {{}}
          </p>
        </template>
        <dashboard-employees-pending
          v-else-if="employeeStateCounts.ENROLLING && State.state.claims.a"
          :pending-employees-count="employeeStateCounts.ENROLLING"
        />
        <sme-alert v-else-if="!isYetToLaunch && State.state.claims.a" level="good">
          You have no employees waiting in pending. Well done!
        </sme-alert>
      </div>
    </template>

    <template v-if="!loading" #footer>
      <template v-if="isAddEmployeesRequired">
        <b-button v-if="canIntegrate" :to="{ name: 'sme-integrations' }" variant="outline-primary"
          >Add integration</b-button
        >
        <b-button v-if="canAddEmployees && State.state.claims.a" @click="goToAddEmployees" variant="primary">
          Upload employees
        </b-button>
      </template>
      <template v-else>
        <b-button
          :to="{
            name: 'employees',
            query: { employee_status: employeeStateCounts.ENROLLING > 0 ? 'ENROLLING' : undefined },
          }"
          variant="outline-primary"
          >View employees</b-button
        >
        <b-button v-if="canAddEmployees && State.state.claims.a" @click="goToAddEmployees" variant="primary">
          Upload employees
        </b-button>
      </template>
    </template>
  </sme-card>
</template>

<script setup>
import { computed } from 'vue';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert from '@/components/atoms/SmeAlert';
import SmeCard from '@/components/atoms/SmeCard';
import SmeInfoBox from '@/components/atoms/SmeInfoBox';
import useFeatureFlags from '@/composables/useFeatureFlags';
import DashboardEmployeesChart from '@/pages/dashboard/components/employees/DashboardEmployeesChart.vue';
import DashboardEmployeesPending from '@/pages/dashboard/components/employees/DashboardEmployeesPending';
import State from '@/state/State';
import useEmployees from '@/state/composables/useEmployees';
import useLaunchState from '@/state/composables/useLaunchState';
import { TODO_ACTIONS } from '@/utils/Todo';
import { useRouter } from 'vue-router/composables';
import useAnalytics from '@/state/composables/useAnalytics';

const $router = useRouter();
const { postAnalyticsEvent } = useAnalytics();
const { canAddEmployees, canIntegrate } = useFeatureFlags();
const { employeeStateCounts, employeeStateCountsLoading } = useEmployees();
const { isYetToLaunch, loading: loadingLaunchState, outstandingTodos } = useLaunchState();

const loading = computed(() => loadingLaunchState.value || employeeStateCountsLoading.value);
const isPayscheduleBlackout = false;

const totalEmployeeCount = computed(() => {
  return (
    employeeStateCounts.value.NEW +
    employeeStateCounts.value.ELIGIBLE +
    employeeStateCounts.value.ENROLLING +
    employeeStateCounts.value.ENROLLED
  );
});

const isAddEmployeesRequired = computed(() => {
  return (
    outstandingTodos.value.find(todo => todo.action === TODO_ACTIONS.ADD_EMPLOYEES) || totalEmployeeCount.value === 0
  );
});

const series = computed(() => [
  employeeStateCounts.value.NEW,
  employeeStateCounts.value.ENROLLING,
  employeeStateCounts.value.ENROLLED,
]);

const goToAddEmployees = () => {
  postAnalyticsEvent({
    intent: 'navigate_dashboard_employees_add_employees',
    task: 'add_employees',
    action: 'click',
  });
  $router.push({ name: 'employees-upload' });
};
</script>
