<template>
  <div class="employee-list">
    <page-list-header>
      <template #left>
        <settings-dropdown v-model="settings" :settings="SETTINGS_ITEMS" id="employees-list" />
        <inline-search v-model="searchText" @search="onSearch" class="mb-0" />
        <filter-dropdown v-model="filters" :filters="filterItems" :key="filtersKey" />
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="perPageOptions" />
        </b-form-group>
        <export-button
          :file-name="exportFilename"
          :export-data-function="getUnpaginatedEmployees"
          :export-file-rows="exportFileRows"
          :export-headers="exportHeaders"
        />
      </template>
    </page-list-header>

    <sme-card class="mb-3">
      <AppLoading v-if="loading"></AppLoading>
      <sme-detailed-table
        v-else
        :fields="FIELDS"
        :items="employees"
        :tbody-tr-class="getRowClasses"
        show-empty
        @row-clicked="item => $router.push({ name: 'employee', params: { employeeId: item.employee_id } })"
      >
        <template #cell(details)="data">
          <div class="sme-detailed-table__cell sme-detailed-table__cell--wide">
            <strong class="sme-detailed-table__label sme-detailed-table__label--large mb-1">{{
              data.item.full_name
            }}</strong>
            <strong class="sme-detailed-table__value sme-detailed-table__value--primary">{{
              data.item.employee_code
            }}</strong>
            <span v-if="data.item.other_entities?.length > 1 || false" class="sme-detailed-table__footnote">
              <router-link
                :to="{ name: 'employee', params: { employeeId: data.item.employee_id }, query: { tab: 'assignments' } }"
                @click.stop.prevent
                ><font-awesome-icon :icon="['fad', 'user-group']" class="mr-2" />
                {{ data.item.other_entities.length }} employee
                {{ pluralize('record', data.item.other_entities.length) }}</router-link
              ></span
            >
          </div>
        </template>
        <template #cell(current_state)="data">
          <div
            class="sme-detailed-table__cell sme-detailed-table__cell--center sme-detailed-table__cell--center-column"
          >
            <sme-badge :variant="badgeVariant(data.item)" pill>
              {{ convertStatus(data.item) }}
            </sme-badge>
            <div v-if="getLatestStatusChange(data.item)" class="sme-detailed-table__cell">
              <span class="sme-detailed-table__label sme-detailed-table__label--top-margin">Last state change</span>
              <strong class="sme-detailed-table__value sme-detailed-table__value--small">{{
                getLatestStatusChange(data.item)
              }}</strong>
            </div>
          </div>
        </template>
        <template #cell(personal_banking)="data">
          <div v-if="getEmployeeAccountNumber(data.item)" class="sme-detailed-table__cell">
            <span class="sme-detailed-table__label">Personal bank</span>
            <strong class="sme-detailed-table__value">{{ getEmployeeAccountNumber(data.item) }}</strong>
            <span class="sme-detailed-table__value">{{
              getEmployeeSortCode(data.item, state.company.default_currency)
            }}</span>
          </div>
        </template>
        <template #cell(wagestream_banking)="data">
          <div v-if="data.item.properties.payment_account_number" class="sme-detailed-table__cell">
            <span class="sme-detailed-table__label">Wagestream bank</span>
            <strong class="sme-detailed-table__value">{{ data.item.properties.payment_account_number }}</strong>
            <span class="sme-detailed-table__value">{{
              formatSortCode(data.item.properties.payment_sort_code, state.company.default_currency)
            }}</span>
          </div>
        </template>
        <template #cell(bank_pay)="data">
          <div
            v-if="state.company.properties.weekly_pay_settings?.enabled"
            class="sme-detailed-table__cellm sme-detailed-table__cell sme-detailed-table__cell--center"
          >
            <div v-if="data.item.signed_up_for_weekly_pay">
              <sme-badge class="sme-detailed-table__badge">BANK PAY</sme-badge>
            </div>
          </div>
        </template>
        <template #cell(disable_enrol)="data">
          <div class="sme-detailed-table__cell sme-detailed-table__cell--ctas">
            <span
              v-if="canEnrollEmployee(data.item) && state.claims.a"
              v-b-tooltip.hover.noninteractive="
                data.item?.instant_payment_method_status === 'AWAITING_INSTANT_PAYMENT_METHOD'
              "
              :title="
                data.item?.instant_payment_method_status === 'AWAITING_INSTANT_PAYMENT_METHOD'
                  ? 'Employee must link a debit card before they can be enrolled'
                  : ''
              "
            >
              <b-button
                :to="{ name: 'employee-setup', params: { employeeId: data.item.employee_id } }"
                class="sme-detailed-table__cta"
                variant="outline-primary"
                :disabled="data.item?.instant_payment_method_status === 'AWAITING_INSTANT_PAYMENT_METHOD'"
              >
                <font-awesome-icon :icon="['fad', 'user-plus']" class="mr-2" />Enroll
              </b-button>
            </span>

            <b-button
              v-if="canOptOutEmployee(data.item)"
              :to="{ name: 'employee-opt-out', params: { employeeId: data.item.employee_id } }"
              class="sme-detailed-table__cta"
              variant="outline-secondary"
            >
              <font-awesome-icon :icon="['fad', 'user-minus']" class="mr-2" />Opt Out
            </b-button>

            <b-button
              v-if="canOptInEmployee(data.item)"
              :to="{ name: 'employee-opt-in', params: { employeeId: data.item.employee_id } }"
              class="sme-detailed-table__cta"
              variant="outline-info"
            >
              <font-awesome-icon :icon="['fad', 'user-plus']" class="mr-2" />Opt In
            </b-button>

            <b-button
              :to="{ name: 'employee-revert-banking', params: { employeeId: data.item.employee_id } }"
              class="sme-detailed-table__cta"
              variant="outline-secondary"
              v-if="canCompleteOptOutForEmployee(data.item)"
            >
              <font-awesome-icon :icon="['fas', 'circle-check']" class="mr-2" />Complete
            </b-button>

            <b-button
              :to="{ name: 'employee-complete-unenrolment', params: { employeeId: data.item.employee_id } }"
              class="sme-detailed-table__cta"
              variant="outline-secondary"
              v-if="canCompleteUnenrolmentForEmployee(data.item)"
            >
              <font-awesome-icon :icon="['fas', 'circle-check']" class="mr-2" />Complete
            </b-button>
          </div>
        </template>
        <template #row-details="row">
          <sme-alert :level="getEmployeeAlertLevel(row.item.employee_id)" class="p-0" flush>
            {{ getEmployeeIssue(row.item.employee_id) }}
          </sme-alert>
        </template>
      </sme-detailed-table>
    </sme-card>

    <b-pagination
      v-model="pagination.offset"
      :total-rows="pagination.count"
      :per-page="pagination.limit"
      class="justify-content-end"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import moment from 'moment/moment';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import { CONTROL_TYPES } from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import ExportButton from '@/components/ExportButton.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import SettingsDropdown from '@/components/SettingsDropdown.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import SmeBadge from '@/components/atoms/SmeBadge.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import SmeDetailedTable from '@/components/atoms/SmeDetailedTable.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { employeeBadges } from '@/mixins/EmployeeBadges.js';
import State from '@/state/State';
import useEmployeesReport from '@/state/composables/useEmployeesReport';
import usePayschedules from '@/state/composables/usePayschedules';
import {
  EMPLOYEE_PAY_TYPE,
  canCompleteOptOutForEmployee,
  canCompleteUnenrolmentForEmployee,
  canOptOutEmployee,
  canOptInEmployee,
  canEnrollEmployee,
} from '@/utils/Employee';
import { formatSortCode, toTitleCase, pluralize } from '@/utils/common';

const FIELDS = [
  {
    key: 'details',
    label: 'Employee',
  },
  {
    key: 'current_state',
    label: 'Status',
  },
  {
    key: 'personal_banking',
    label: 'Personal banking',
  },
  {
    key: 'wagestream_banking',
    label: 'Wagestream banking',
  },
  {
    key: 'bank_pay',
    label: 'Bank Pay',
  },
  {
    key: 'disable_enrol',
    label: 'Actions',
  },
];

const DEFAULT_EMPLOYEE_STATUS_FILTER_OPTIONS = [
  'NOT ENROLLED',
  'ENROLLED',
  'ENROLLING',
  'PAUSED',
  'OPT OUT PENDING',
  'PAUSE SCHEDULED',
];
const DISABLED_EMPLOYEE_STATUS_FILTER_OPTIONS = ['OPTED OUT', 'LEAVER'];

export const FILTER_KEYS = {
  EMPLOYEE_STATUS: 'employee_status',
  PAY_SCHEDULE: 'pay_schedule',
  SALARY_OR_HOURLY: 'salary_or_hourly',
  DIVISION: 'division',
  SAVINGS_ACCOUNT: 'has_savings_account',
  TERMINATED_FROM: 'terminated_from',
  TERMINATED_TO: 'terminated_to',
};

const SETTING_KEYS = {
  SHOW_DISABLED: 'show_disabled',
};

const SETTINGS_ITEMS = [
  {
    key: SETTING_KEYS.SHOW_DISABLED,
    label: 'Show Leavers and Opt Outs',
    type: CONTROL_TYPES.CHECKBOX_SINGLE,
  },
];

const EMPLOYEE_ISSUES = {
  INVALID_BANK_DETAILS: "Something looks wrong with this employee's bank details.",
  MISSED_SALARY: "We didn't receive a salary for this employee. Please contact support.",
  NEVER_PAID: "Make sure your payroll is updated with this employee's Wagestream bank details.",
};

export default {
  name: 'EmployeesList',
  mixins: [employeeBadges],
  components: {
    ExportButton,
    FilterDropdown,
    InlineSearch,
    PageListHeader,
    SettingsDropdown,
    SmeAlert,
    SmeBadge,
    SmeCard,
    SmeDetailedTable,
    AppLoading,
  },
  props: {
    extraFields: Array,
  },
  data() {
    return {
      FIELDS,
      SETTINGS_ITEMS,
      filters: {},
      filtersKey: 0,
      searchText: '',
      searchCriteria: '',
      settings: {},
      divisions: [],
      divisionEmployees: [],
      report: null,
      currentPage: 1,
      perPage: 50,
      perPageOptions: [25, 50, 100],
      state: State.state,
      employees: [],
      pagination: { count: 0, offset: 1, limit: 50 },
      loading: true,
      initial: true,
    };
  },
  setup() {
    const {
      invalidBankDetailsEmployees,
      missedSalaryEmployees,
      neverPaidEmployees,
      getEmployeesReport,
      isInvalidBankDetailsEmployee,
      isMissedSalaryEmployee,
      isNeverPaidEmployee,
    } = useEmployeesReport();
    const { payschedules, getPayschedules } = usePayschedules();

    const { isEnableHideNonEnrolled, hideStreamAccountRelatedItems } = useFeatureFlags();

    return {
      invalidBankDetailsEmployees,
      missedSalaryEmployees,
      neverPaidEmployees,
      payschedules,
      getEmployeesReport,
      getPayschedules,
      isInvalidBankDetailsEmployee,
      isMissedSalaryEmployee,
      isNeverPaidEmployee,
      isEnableHideNonEnrolled,
      hideStreamAccountRelatedItems,
    };
  },
  async mounted() {
    try {
      await this.getPayschedules();
      await Promise.all([
        (async () => (this.divisions = (await ApiClient.getDivisions())?.data || []))(),
        (async () => (this.divisionEmployees = (await ApiClient.getEmployeeDivisionInfo())?.data || []))(),
        (async () => await this.getPaginatedEmployees())(),
      ]);
      this.initial = false;
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    startDate() {
      if (this.payschedules && this.payschedules.length) {
        let startDate = this.payschedules
          .map(payschedule => new Date(payschedule.pay_period_start))
          .sort((a, b) => a > b)[0];
        startDate.setDate(startDate.getDate() - 1);
        startDate = startDate.toISOString().split('T')[0];

        return startDate;
      }

      return null;
    },
    endDate() {
      let endDate = new Date();
      endDate = endDate.toISOString().split('T')[0];

      return endDate;
    },
    filterItems() {
      const employeeStatusFilterOptions = [
        ...DEFAULT_EMPLOYEE_STATUS_FILTER_OPTIONS,
        ...(this.settings[SETTING_KEYS.SHOW_DISABLED] ? DISABLED_EMPLOYEE_STATUS_FILTER_OPTIONS : []),
      ].map(value => ({ text: toTitleCase(value), value }));

      return [
        {
          key: FILTER_KEYS.EMPLOYEE_STATUS,
          label: 'Employee Status',
          type: CONTROL_TYPES.CHECKBOX,
          options: employeeStatusFilterOptions,
        },
        {
          key: FILTER_KEYS.PAY_SCHEDULE,
          label: 'Pay Schedule',
          type: CONTROL_TYPES.RADIO,
          options: this.payschedules.map(payschedule => ({
            text: payschedule.name,
            value: payschedule.pay_schedule_id,
          })),
          hidden: !this.payschedules.length,
        },
        {
          key: FILTER_KEYS.SALARY_OR_HOURLY,
          label: 'Pay Type',
          type: CONTROL_TYPES.RADIO,
          options: Object.values(EMPLOYEE_PAY_TYPE).map(value => ({ text: toTitleCase(value), value })),
        },
        {
          key: FILTER_KEYS.DIVISION,
          label: 'Division',
          type: CONTROL_TYPES.CHECKBOX,
          options: this.divisions.map(division => ({
            text: division.name,
            value: division.division_id,
          })),
          hidden: !this.divisions.length,
        },
        {
          key: FILTER_KEYS.SAVINGS_ACCOUNT,
          label: 'Has Savings Account',
          type: CONTROL_TYPES.RADIO,
          options: ['Yes', 'No'].map(value => ({ text: value, value })),
        },
        {
          key: FILTER_KEYS.TERMINATED_FROM,
          label: 'Leaver From',
          type: CONTROL_TYPES.DATE,
          hidden: !this.settings?.show_disabled,
          controlProps: {
            max: this.filters[FILTER_KEYS.TERMINATED_TO] ? this.filters[FILTER_KEYS.TERMINATED_TO] : null,
          },
        },
        {
          key: FILTER_KEYS.TERMINATED_TO,
          label: 'Leaver To',
          type: CONTROL_TYPES.DATE,
          hidden: !this.settings?.show_disabled,
          controlProps: {
            min: this.filters[FILTER_KEYS.TERMINATED_FROM] ? this.filters[FILTER_KEYS.TERMINATED_FROM] : null,
          },
        },
      ];
    },
    exportFilename() {
      const now = moment().format('YYYY-MM-DD');
      let companyName = '';
      if (this.state.company.name) {
        companyName = this.state.company.name.toLowerCase()?.replace(/ /g, '-');
      }

      return `report-${now}-employee-list-${companyName}`;
    },
    exportHeaders() {
      const baseHeaders = [
        'Employee Code',
        'Name',
        'Status',
        'Latest State Change',
        'Personal Account No.',
        `Personal ${getBankingInfo().sortCode.label}`,
        'Wagestream Account No.',
        `Wagestream ${getBankingInfo().sortCode.label}`,
      ];

      return this.state.company?.properties?.weekly_pay_settings?.enabled ? [...baseHeaders, 'Bank Pay'] : baseHeaders;
    },
  },
  watch: {
    [`settings.${SETTING_KEYS.SHOW_DISABLED}`]: {
      immediate: true,
      handler(showDisabled) {
        if (showDisabled === undefined) {
          return;
        }

        if (!showDisabled && this.filters[FILTER_KEYS.EMPLOYEE_STATUS]) {
          this.filters[FILTER_KEYS.EMPLOYEE_STATUS] = this.filters[FILTER_KEYS.EMPLOYEE_STATUS].filter(
            value => !DISABLED_EMPLOYEE_STATUS_FILTER_OPTIONS.includes(value),
          );
        }

        this.$nextTick(() => this.filtersKey++);
      },
    },
    ['pagination.offset']: {
      initial: false,
      handler() {
        !this.initial && this.getPaginatedEmployees();
      },
    },
    ['perPage']: {
      initial: false,
      handler() {
        this.pagination.limit = this.perPage;
        !this.initial && this.getPaginatedEmployees();
      },
    },
    ['searchCriteria']: {
      initial: false,
      handler() {
        this.pagination.offset = 1;
        !this.initial && this.getPaginatedEmployees();
      },
    },
    ['filters']: {
      initial: false,
      deep: true,
      handler() {
        this.pagination.offset = 1;
        !this.initial && this.getPaginatedEmployees();
      },
    },
    ['settings']: {
      initial: false,
      deep: true,
      handler() {
        this.filters = {};
        this.pagination.offset = 1;
        !this.initial && this.getPaginatedEmployees();
      },
    },
  },
  methods: {
    pluralize,
    formatSortCode,
    canCompleteOptOutForEmployee,
    canCompleteUnenrolmentForEmployee,
    canOptOutEmployee,
    canOptInEmployee,
    canEnrollEmployee,
    getEmployeeAccountNumber(item) {
      return (
        item.properties.beneficiary_account_number || item.properties.sme_properties?.personal_account_number || ''
      );
    },
    getEmployeeSortCode(item, currency) {
      return (
        formatSortCode(
          item.properties.beneficiary_sort_code || item.properties.sme_properties?.personal_sort_code,
          currency,
        ) || ''
      );
    },
    getRowClasses(item) {
      if (!item) {
        return;
      }

      const classes = ['sme-detailed-table__row--link'];
      const alertLevel = this.getEmployeeAlertLevel(item.employee_id);

      if (alertLevel) {
        classes.push(`sme-detailed-table__row--${alertLevel}`);
      }

      return classes;
    },
    getEmployeeAlertLevel(employeeId) {
      const issue = this.getEmployeeIssue(employeeId);

      if (!issue) {
        return;
      }

      return issue === EMPLOYEE_ISSUES.NEVER_PAID ? ALERT_LEVELS.WARNING : ALERT_LEVELS.DANGER;
    },
    getEmployeeIssue(employeeId) {
      if (this.isInvalidBankDetailsEmployee(employeeId) && !this.hideStreamAccountRelatedItems) {
        return EMPLOYEE_ISSUES.INVALID_BANK_DETAILS;
      } else if (this.isMissedSalaryEmployee(employeeId)) {
        return EMPLOYEE_ISSUES.MISSED_SALARY;
      } else if (
        this.isNeverPaidEmployee(employeeId) &&
        this.companyShouldDisplayYetToBePaid &&
        !this.hideStreamAccountRelatedItems
      ) {
        return EMPLOYEE_ISSUES.NEVER_PAID;
      }
    },
    exportFileRows(item) {
      const baseRows = [
        item.employee_code,
        item.full_name,
        this.convertStatus(item),
        this.getLatestStatusChange(item),
        this.getEmployeeAccountNumber(item),
        this.getEmployeeSortCode(item, State.state.company.default_currency),
        item.properties.payment_account_number ? item.properties.payment_account_number : '',
        item.properties.payment_sort_code
          ? formatSortCode(item.properties.payment_sort_code, State.state.company.default_currency)
          : '',
      ];

      return State.state.company?.properties?.weekly_pay_settings?.enabled
        ? [...baseRows, item.signed_up_for_weekly_pay ? 'Enabled' : '']
        : baseRows;
    },
    async onSearch() {
      this.searchCriteria = this.searchText;
    },
    // todo move this to a common function
    convertStatuses(statuses) {
      // pretty much the reverse of https://github.com/wagestream/sme-portal/blob/9fbab16761df96a4dec96a194ccf1f2359c86c5f/src/utils/Employee.js#L69
      const newStatuses = {
        status: [],
        paused: false,
        pause_scheduled: false,
        optout_pending: false,
        optout_complete: false,
        terminated: false,
      };
      for (const status of statuses) {
        if (status === 'OPT OUT PENDING') {
          newStatuses.optout_pending = true;
        } else if (status === 'LEAVER') {
          newStatuses.terminated = true;
        } else if (status === 'OPTED OUT') {
          newStatuses.optout_complete = true;
        } else if (status === 'PAUSED') {
          newStatuses.paused = true;
        } else if (status === 'PAUSE SCHEDULED') {
          newStatuses.pause_scheduled = true;
        } else if (status === 'NOT ENROLLED') {
          newStatuses.status.push('NEW');
          newStatuses.status.push('INSTALLED');
        } else if (status === 'ENROLLED') {
          newStatuses.status.push('ENROLLED');
          newStatuses.status.push('ACTIVE');
        } else if (status === 'ENROLLING') {
          newStatuses.status.push('ENROLLING');
          newStatuses.status.push('PENDING');
        } else if (status === 'DISABLED') {
          newStatuses.status.push('FURLOUGHED');
          newStatuses.status.push('DISABLED');
          newStatuses.status.push('OPTOUTPENDING');
        }
      }
      return newStatuses;
    },
    getLatestStatusChange(item) {
      return item.current_date_created_at || '';
    },
    async getUnpaginatedEmployees() {
      const params = {
        offset: 0,
        limit: 999999,
        company_id: State.state.company.company_id,
      };
      const response = await this.getEmployees(params);
      return response.data || [];
    },
    getPaginatedEmployees: debounce(async function () {
      this.loading = true;
      const params = {
        offset: this.pagination.offset * this.pagination.limit - this.pagination.limit,
        limit: this.pagination.limit,
        company_id: State.state.company.company_id,
      };
      const response = await this.getEmployees(params);

      this.employees = response?.data || [];
      this.pagination = {
        ...this.pagination,
        count: response?.pagination?.count || 0,
      };

      this.loading = false;
      return response || [];
    }, 1000),
    async getEmployees(params) {
      try {
        const statusParams = this.convertStatuses(this.filters?.employee_status || []);

        // conditionally add params based on filters
        this.searchText && (params.search_text = this.searchText);
        this.filters.pay_schedule && (params.pay_schedule_id = this.filters.pay_schedule);
        this.filters.salary_or_hourly && (params.pay_type = this.filters.salary_or_hourly);
        this.filters.division?.length && (params.division_ids = this.filters.division.join(','));
        statusParams?.status?.length && (params.status = statusParams.status);
        statusParams?.paused && (params.paused = statusParams.paused);
        statusParams?.pause_scheduled && (params.pause_scheduled = statusParams.pause_scheduled);
        statusParams?.optout_pending && (params.optout_pending = statusParams.optout_pending);
        statusParams?.optout_complete && (params.optout_complete = statusParams.optout_complete);
        statusParams?.terminated && (params.terminated = statusParams.terminated);

        if (!this.settings?.show_disabled) {
          if (
            !params?.status?.length &&
            !params?.paused &&
            !params?.pause_scheduled &&
            !params?.optout_pending &&
            !params?.optout_complete &&
            !params?.terminated
          ) {
            params.status = ['NEW', 'INSTALLED', 'PENDING', 'ENROLLING', 'ENROLLED', 'ACTIVE'];
            params.terminated_of_interest = true;
          } else {
            params.status = params?.status?.filter(status => status !== 'DISABLED') || [];
          }
        }

        if (this.isEnableHideNonEnrolled) {
          if (
            !params?.status?.length &&
            !params?.paused &&
            !params?.optout_pending &&
            !params?.optout_complete &&
            !params?.terminated
          ) {
            params.status = ['ENROLLED', 'ACTIVE'];
            params.terminated_of_interest = true;
          } else {
            params.status = params?.status?.filter(status => status === 'ENROLLED' || status === 'ACTIVE') || [];
          }
        }

        if (this.filters?.has_savings_account) {
          params.has_savings_account = this.filters.has_savings_account === 'Yes';
        }

        if (this.filters?.terminated_from || this.filters?.terminated_to) {
          params.terminated_of_interest = false;
          params.terminated_from = this.filters?.terminated_from ? this.filters?.terminated_from : '2001-01-01';
          params.terminated_to = this.filters?.terminated_to
            ? this.filters?.terminated_to
            : moment().format('YYYY-MM-DD');
        }

        // convert status array to comma separated string
        if (params.status?.length > 0) {
          params.status = params.status.join(',');
        }

        return await ApiClient.getPaginatedEmployees(params);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
