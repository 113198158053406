<template>
  <b-badge v-bind="attrs" :variant="variant" class="sme-badge" :class="[large && 'sme-badge--large']" pill>
    <slot></slot>
  </b-badge>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useAttrs } from 'vue';

const attrs = useAttrs();

defineProps({
  variant: {
    type: String,
    default: 'primary-light',
  },
  large: Boolean,
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

$variants: (
  primary-light: (
    background: var(--palette-color-core-1-lighten-70),
    background-hover: var(--palette-color-brand-primary),
    color: var(--palette-color-brand-primary),
    color-hover: var(--palette-color-base-white),
  ),
  secondary-light: (
    background: var(--palette-color-core-4-lighten-90),
    background-hover: var(--palette-color-core-4),
    color: var(--palette-color-core-4),
    color-hover: var(--palette-color-base-white),
  ),
  tertiary-light: (
    background: var(--palette-color-core-5-lighten-90),
    background-hover: var(--palette-color-core-5),
    color: var(--palette-color-core-5),
    color-hover: var(--palette-color-base-white),
  ),
);

.sme-badge {
  font-size: 12px;
  padding: 0.5rem 0;
  text-shadow: none;
  width: 12rem;

  @each $variant, $colours in $variants {
    &.badge-#{$variant} {
      background-color: map.get($colours, 'background');
      color: map.get($colours, 'color');

      &[href]:hover {
        background-color: map.get($colours, 'background-hover');
        color: map.get($colours, 'color-hover');
      }
    }
  }
}

.sme-badge--large {
  font-size: 14px;
  width: 12rem;

  @media (min-width: 1024px) {
    font-size: 16px;
    width: 12rem;
  }
}
</style>
