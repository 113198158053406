var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c(_setup.PageSubHeader,{staticClass:"mt-4",attrs:{"title":"Track"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-end"},[_c(_setup.AppInput,{staticClass:"text-uppercase mb-0",attrs:{"options":_setup.TIME_SERIES_VALUES,"type":"radio","buttons":"","button-variant":"outline-primary","size":"sm"},model:{value:(_setup.timeSeries),callback:function ($$v) {_setup.timeSeries=$$v},expression:"timeSeries"}}),(_setup.isDataReady)?_c(_setup.ExportButton,{staticClass:"btn mt-0",attrs:{"file-name":`track-metrics-${_setup.timeSeries}`,"export-data":_setup.exportData,"export-file-rows":_setup.exportFileRows,"export-headers":_setup.exportHeaders}}):_vm._e()],1)]},proxy:true}])}),(_setup.loading)?_c(_setup.SmeCard,[_c(_setup.AppLoading)],1):[(_setup.timeGroupedCountDataset.values.length)?_c(_setup.SmeCard,[_c('b-row',{staticClass:"align-items-center mb-4"},[_c('b-col',{staticClass:"mb-3 mb-lg-0",attrs:{"lg":"8"}},[_c(_setup.LineChart,{attrs:{"labels":_setup.timeGroupDataset,"datasets":_setup.timeGroupedCountDataset,"chart-options":{
              aspectRatio: _setup.getInitialAspectRatio(3, 2),
              scales: { y: { ticks: { precision: 0 }, title: { text: _setup.timeGroupedCountDataset.name } } },
            },"fill-gradient":""}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c(_setup.AppStat,{staticClass:"mb-3",attrs:{"stat":_setup.lastCountValue,"previous-stat":_setup.prevCountValue,"formatter":_setup.toCompactNumber,"description":"Employees Viewing Track","background-color":"core-1-lighten-90","color":"default"}}),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_setup.timeGroupTitle)+", "+_vm._s(_setup.toCompactNumber(_setup.lastCountValue))+" of your enrolled employees viewed track. "+_vm._s(_setup.countPrevDiffText)+" ")])],1)],1),_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{staticClass:"mb-3 mb-lg-0",attrs:{"lg":"8"}},[_c(_setup.LineChart,{attrs:{"labels":_setup.timeGroupDataset,"datasets":_setup.timeGroupedPercentageDataset,"chart-options":{
              aspectRatio: _setup.getInitialAspectRatio(3, 2),
              scales: { y: { title: { text: _setup.timeGroupedPercentageDataset.name } } },
            },"fill-gradient":""}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c(_setup.AppStat,{staticClass:"mb-3",attrs:{"stat":_setup.lastPercentageValue,"previous-stat":_setup.prevPercentage,"formatter":_setup.toNumber,"suffix":"%","description":"Track Percentage","background-color":"core-1-lighten-90","color":"default"}}),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_setup.timeGroupTitle)+", "+_vm._s(_setup.lastPercentageValue)+"% of your enrolled employees viewed track. "+_vm._s(_setup.percentagePrevDiffText)+" ")])],1)],1)],1):_c(_setup.SmeCard,[_c('p',{staticClass:"my-2 text-center"},[_vm._v("No data to display.")])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }