import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const US_DATE_FORMAT = 'MM/DD/YYYY';
export const US_TIME_FORMAT = 'hh:mm A';

export const toFormattedDate = (date, dateFormat = null) => {
  if (!date) {
    return date;
  }
  if (dateFormat != null) {
    return moment(date).format(dateFormat);
  }
  if (navigator.language === 'en-US') {
    return moment(date).format(US_DATE_FORMAT);
  }
  return moment(date).format(DEFAULT_DATE_FORMAT);
};

export const toFormattedDateTime = value => {
  if (navigator.language === 'en-US') {
    return moment(value).format(`${US_DATE_FORMAT} ${US_TIME_FORMAT}`);
  }
  return moment(value).format(`${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`);
};

export const toFormattedDateAllowNulls = (date, dateFormat = DEFAULT_DATE_FORMAT) =>
  date ? moment(date).format(dateFormat) : null;

// finds days between 2 dates
export const daysBetweenDates = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  // If exact same date and time
  if (start.isSame(end)) {
    return 0;
  }

  // Get the day difference and add 1 to include both start and end days
  return end.startOf('day').diff(start.startOf('day'), 'days') + 1;
};

export const toDateFromNowText = date => (date ? moment.utc(date).fromNow() : '');

export const toMonthDescriptor = (date, monthPrefix = 'in ') => {
  const currentMonth = moment().month();
  const dateMonth = moment(date).month();

  if (currentMonth === dateMonth) {
    return 'this month';
  } else if (dateMonth === currentMonth - 1) {
    return 'last month';
  }

  return `${monthPrefix}${moment(date).format('MMMM')}`;
};

export const DATE_FORMATS = [
  'YYYY-MM-DD',
  'DD-MM-YYYY',
  'MM-DD-YYYY',
  'YYYY/MM/DD',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'DD.MM.YYYY',
  'MM.DD.YYYY',
  'YYYY.MM.DD',
  'D-MMM-YYYY',
  'MMM DD, YYYY',
  'DD MMM, YYYY',
  'MMMM DD, YYYY',
  'DD MMMM YYYY',
  'YY-MM-DD',
  'DD-MM-YY',
  'MM-DD-YY',
  'DD/MM/YY',
  'MM/DD/YY',
  'YYYYMMDD',
  'DD-MMM-YY',
  'YY/MM/DD',
  'D MMM YY',
  'MMM D, YYYY',
  'YYYY-MMM-DD',
  'YY-MMM-DD',
  'MM/DD/YYYY HH:mm:ss',
  'DD-MM-YYYY HH:mm:ss',
  'YYYY-MM-DDTHH:mm:ssZ',
  'YYYY, MMM DD',
];

export const formatDateIfValid = (date, format = 'YYYY-MM-DD') => {
  if (moment(date, format, true).isValid()) {
    return moment(date, format).format(format);
  }
  return date;
};
