<template>
  <section>
    <page-sub-header :title="bankingInfo.sdl.label" class="mt-4">
      <template #header>
        <div class="d-flex flex-row align-items-center justify-content-end">
          <app-input
            v-model="timeSeries"
            :options="TIME_SERIES_VALUES"
            type="radio"
            buttons
            button-variant="outline-primary"
            class="text-uppercase mb-0"
            size="sm"
          />
          <export-button
            v-if="isDataReady"
            :file-name="`sdl-metrics-${timeSeries}`"
            :export-data="exportData"
            :export-file-rows="exportFileRows"
            :export-headers="exportHeaders"
            class="btn mt-0"
          />
        </div>
      </template>
    </page-sub-header>
    <sme-card v-if="loading">
      <app-loading />
    </sme-card>
    <template v-else>
      <sme-card v-if="timeGroupedLoansIssuedDataset.values.length" class="mb-4">
        <b-row class="align-items-center mb-4">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedLoansIssuedDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { ticks: { precision: 0 }, title: { text: timeGroupedLoansIssuedDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastGroupedLoansIssued"
              :previous-stat="prevGroupedLoansIssued"
              :formatter="toCompactNumber"
              description="Loans"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ toCompactNumber(lastGroupedLoansIssued) }} of your employees were issued a
              {{ bankingInfo.sdl.smallLabel }}.
              {{ loansIssuedPrevDiffText }}
            </p>
          </b-col>
        </b-row>

        <b-row class="align-items-center">
          <b-col lg="8" class="mb-3 mb-lg-0">
            <line-chart
              :labels="timeGroupDataset"
              :datasets="timeGroupedLoansIssuedPercentageDataset"
              :chart-options="{
                aspectRatio: getInitialAspectRatio(3, 2),
                scales: { y: { title: { text: timeGroupedLoansIssuedPercentageDataset.name } } },
              }"
              fill-gradient
            />
          </b-col>
          <b-col lg="4">
            <app-stat
              :stat="lastGroupedLoansIssuedPercentage"
              :previous-stat="prevGroupedLoansIssuedPercentage"
              :formatter="toNumber"
              suffix="%"
              description="Loans Percentage"
              class="mb-3"
              background-color="core-1-lighten-90"
              color="default"
            />
            <p class="mb-0">
              {{ timeGroupTitle }}, {{ lastGroupedLoansIssuedPercentage }}% of your enrolled employees were issued a
              {{ bankingInfo.sdl.smallLabel }}.
              {{ loansIssuedPercentagePrevDiffText }}
            </p>
          </b-col>
        </b-row>
      </sme-card>

      <b-row class="mb-4">
        <b-col cols="6" lg="6" class="mb-4 mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="avgLoanAmountDataset.calculated.mean || 0"
              :formatter="value => toCompactNumber(Math.round(value))"
              :prefix="currencySymbolFromCode(State.state.company.default_currency)"
              :description="`Average ${timeSeriesUnit}ly loan amount`"
              :class="[avgLoanAmountDataset.values.length && 'mb-3']"
              background-color="core-5-lighten-90"
              color="default"
              small
            />
            <line-chart
              v-if="avgLoanAmountDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="avgLoanAmountDataset"
              :chart-options="merge({}, CHART_PRESET_OPTIONS.MINIMAL)"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>

        <b-col cols="6" lg="6" class="mb-4 mb-lg-0">
          <sme-card class="h-100 overflow-hidden">
            <app-stat
              :stat="loanIssuedDataset.calculated.mean || 0"
              :formatter="value => toCompactNumber(Math.round(value))"
              :description="`Average ${timeSeriesUnit}ly loans issued`"
              :class="[loanIssuedDataset.values.length && 'mb-3']"
              background-color="core-5-lighten-90"
              color="default"
              small
            />
            <line-chart
              v-if="loanIssuedDataset.values.length"
              :labels="timeGroupDataset"
              :datasets="loanIssuedDataset"
              :chart-options="CHART_PRESET_OPTIONS.MINIMAL"
              :display-points="false"
              class="mx-n3 mt-auto mb-n3"
              fill-gradient
            />
          </sme-card>
        </b-col>
      </b-row>

      <sme-card v-if="loanPurposeDatasets?.some(dataset => !!dataset.values.length)">
        <line-chart
          :labels="loanPurposeTimeGroupDataset"
          :datasets="timeGroupedLoanPurposeDataset"
          :chart-options="
            merge(
              {
                aspectRatio: getInitialAspectRatio(3.5, 2),
                scales: { y: { title: { text: 'Percentage (%)' } } },
                plugins: {
                  legend: { display: true, position: 'right' },
                  title: { display: true, text: 'Loan purpose' },
                },
              },
              CHART_PRESET_OPTIONS.PERCENTAGE,
            )
          "
        />
      </sme-card>
    </template>
  </section>
</template>

<script setup>
import merge from 'lodash/merge';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import { PaletteColors } from '@/Theme';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppStat from '@/components/AppStat.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import LineChart, { CHART_PRESET_OPTIONS } from '@/components/charts/LineChart.vue';
import useTimeGroupDataset from '@/composables/useTimeGroupDataset';
import State from '@/state/State';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import { TIME_SERIES_VALUES, getDatasetColor, getInitialAspectRatio } from '@/utils/chart';
import { toCompactNumber, toNumber, currencySymbolFromCode } from '@/utils/common';
import ExportButton from '@/components/ExportButton.vue';

const { divisionId } = useDivisionSelection();

const loading = ref(true);
const timeDataset = ref(null);
const loanIssuedDataset = ref(null);
const loansIssuedPercentageDataset = ref(null);
const avgLoanAmountDataset = ref(null);

const loanPurposeTimeDataset = ref(null);
const loanPurposeDatasets = ref(null);

const { timeGroupDataset, timeGroupTitle, timeSeries, timeSeriesUnit, datasetToTimeGroupedDataset, getPrevDiffText } =
  useTimeGroupDataset(timeDataset);

const {
  timeGroupDataset: loanPurposeTimeGroupDataset,
  datasetToTimeGroupedDataset: loanPurposeDatasetToTimeGroupedDataset,
} = useTimeGroupDataset(loanPurposeTimeDataset, timeSeries);
const timeGroupedLoanPurposeDataset = computed(() => loanPurposeDatasetToTimeGroupedDataset(loanPurposeDatasets));

const timeGroupedLoansIssuedDataset = computed(() => datasetToTimeGroupedDataset(loanIssuedDataset));
const timeGroupedLoansIssuedPercentageDataset = computed(() =>
  datasetToTimeGroupedDataset(loansIssuedPercentageDataset),
);
const lastGroupedLoansIssued = computed(() => timeGroupedLoansIssuedDataset.value?.calculated.last);
const prevGroupedLoansIssued = computed(() => timeGroupedLoansIssuedDataset.value?.calculated.prev);
const lastGroupedLoansIssuedPercentage = computed(() => timeGroupedLoansIssuedPercentageDataset.value?.calculated.last);
const prevGroupedLoansIssuedPercentage = computed(() => timeGroupedLoansIssuedPercentageDataset.value?.calculated.prev);
const loansIssuedPrevDiffText = computed(() => getPrevDiffText(timeGroupedLoansIssuedDataset));
const loansIssuedPercentagePrevDiffText = computed(() =>
  getPrevDiffText(timeGroupedLoansIssuedPercentageDataset, value => `${Number(toNumber(value))}%`),
);
const bankingInfo = computed(() => getBankingInfo());

watch([divisionId, timeSeries], () => getMetrics());

const getSdlMetrics = async () => {
  const { results } = await ApiClient.getSdlMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const loansIssued = results.find(result => result.id === 'loans_issued');
    const enrolled = results.find(result => result.id === 'enrolled');
    const avgLoanAmount = results.find(result => result.id === 'avg_loan_amount');

    timeDataset.value = time;
    loanIssuedDataset.value = {
      ...loansIssued,
      name: 'Number of loans',
    };
    loansIssuedPercentageDataset.value = {
      name: 'Percentage (%)',
      values: enrolled.values.map((value, index) =>
        value ? Number(toNumber((loansIssued.values[index] / value) * 100)) : 0,
      ),
    };
    avgLoanAmountDataset.value = {
      ...avgLoanAmount,
      name: 'Average amount',
      lineColor: PaletteColors['core-4'],
    };
  }
};

const getSdlPurposeMetrics = async () => {
  const { results } = await ApiClient.getSdlPurposeMetrics(timeSeries.value, divisionId.value);

  if (results?.length) {
    const time = results.find(result => result.id === 'time');
    const categories = results.filter(result => result.id !== 'time');

    loanPurposeTimeDataset.value = time;
    loanPurposeDatasets.value = categories.map((category, index) => ({
      ...category,
      lineColor: PaletteColors[getDatasetColor(index)],
      values: category.values.map(value => toNumber(value * 100)),
    }));
  }
};

const getMetrics = async () => {
  loading.value = true;

  await Promise.all([getSdlMetrics(), getSdlPurposeMetrics()]);

  loading.value = false;
};

onBeforeMount(() => getMetrics());

const isDataReady = computed(() => {
  return (
    timeGroupDataset.value?.values &&
    loanIssuedDataset.value?.values &&
    loansIssuedPercentageDataset.value?.values &&
    avgLoanAmountDataset.value?.values &&
    !loading.value
  );
});

const exportData = computed(() => {
  if (!isDataReady.value) {
    return [];
  }

  return timeGroupDataset.value.values.map((time, index) => ({
    time,
    loans: loanIssuedDataset.value.values[index],
    percentage: loansIssuedPercentageDataset.value.values[index],
    averageAmount: avgLoanAmountDataset.value.values[index],
  }));
});

const exportHeaders = computed(() => {
  if (!isDataReady.value) {
    return [];
  }
  return [
    'Date',
    loanIssuedDataset.value.name,
    loansIssuedPercentageDataset.value.name,
    avgLoanAmountDataset.value.name,
  ];
});

const exportFileRows = item => [item.time, item.loans, item.percentage, Math.round(item.averageAmount)];
</script>
